<template>
    <div style="display: flex;justify-content: center">
        <div class="news_center_with_right_content" style="background: #FFFFFF;margin-top: 110px;">
            <div style="height:50px;background:#FFFFFF;margin-bottom:10px;width: 895px ">
                <div style="float: left;padding: 18px 30px;font-size: 20px;font-weight: bold;">今日头条更多文章</div>
            </div>
            <div><el-divider></el-divider></div>
            <div style="height: auto;width:100%;float: left;">
                <div style="overflow: hidden;padding:10px 30px;margin-bottom: 5px;width: 100%;min-height:175px;" v-for="(item,index) in news_list" :key="index.id">
                    <div class="hand op sc" style="float: left;width: 180px;height:135px; overflow: hidden;" @click="a('/content/info/'+item.id+'/3')">
                        <img :src="item.cover" style="width: 180px;height:135px;border-radius: 5px;" />
                    </div>
                    <div style="float: left; padding:  0 10px;position: relative;height: 85px;width:1248px;">
                        <div style="margin-bottom: 5px;width: 100%;color: #333;font-weight: bold;text-overflow: ellipsis;white-space: nowrap;word-wrap: normal;overflow: hidden;" class="hand op sc" @click="add_info(item.id)">
                            <span style="text-decoration: none;font-size: 18px;width: 80%">{{item.title}}</span>
                        </div>
                        <div style="margin-bottom: 5px;width: 100%;color: rgb(142, 142, 142);letter-spacing:3px;font-size: 16px;height: 62px;text-overflow: ellipsis;word-wrap: normal;overflow: hidden;">
                            {{utils.toText(item.content)}}
                        </div>
                        <div class="hand op sc" style="float: left;width: auto;height: 18px;align-content: center;position: absolute;top:115px;font-weight: 400">
                            发布时间：{{item.time}}
                        </div>
                        <div style="color: #808080;font-size: 12px;overflow: hidden;float: right;margin-top: 20px;margin-right: 10px">
                            <div style="float: left;font-size: 16px" v-if="item.click == null">阅读数：<span style="color: #FA8A00;font-weight: bold;margin-right: 16px;">4</span></div>
                            <div style="float: left;font-size: 16px" v-else>阅读数：<span style="color: #FA8A00;font-weight: bold;margin-right: 16px;">4</span></div>
                            <div style="float: left;font-size: 16px">评论数：<span style="color: #FA8A00;" >1314</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



export default {
    name:"",
    components: {
    
    },
    watch: {
        //监听相同路由下参数变化的时候，从而实现异步刷新
        '$route'() {
            this.id = this.$route.params.id;
            this.get_content_info();
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        }
    },
    data () {
        return {
            news_list:[],
            currentPage: 1,
            pageSize: 21
        }
    },
    props:{

    },
    created:function(){

    },
    mounted: function () {
        var that = this;
        that.getTodayHeadlines();
        window.addEventListener('scroll', this.handleScroll);

        window.onscroll = function(){
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件

            if(scrollTop+windowHeight == scrollHeight){
                //到了这个就可以进行业务逻辑加载后台数据了
                console.log("swrthfjklklt");
            }
        }

        this.$EventBus.$emit("close_im", {is_close: 0});

    },
    methods:{
        content_info(id){
            window.open('/content/info/'+id+"/3");
        },
        getTodayHeadlines(){
            var that = this;
            that.newApi.getTodayHeadlines({
                page: that.currentPage,
                pageSize:that.pageSize,
            }).then((res)=>{
                that.news_list = res.data;
            })
        },
        handleScroll() {
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const clientHeight = document.documentElement.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight) {
                this.getMoreData();
            }
        },
        getMoreData(){
            var that = this;
            const nextPage = that.currentPage + 1;
            that.newApi.getTodayHeadlines({
                page: nextPage,
                pageSize:that.pageSize,
            }).then((res)=>{
                that.news_list = that.news_list.concat(res.data);
                that.currentPage = nextPage;
            })
        },
        search(){
            this.page = 1 ;
            this.news_list = [];
        },
        add_info(id){
            window.open('/content/info/'+id+'/3')
        }
    }
}

</script>

<style scoped>
.news_center_with_right_content{
    width:1500px;
    float:left;
    margin:0 5px;
}
.top{
    display: inline-block;
    width: 44px;
    height: 16px;
    margin-right: 6px;
    margin-top: 2px;
    overflow: hidden;
    text-align: center;
    line-height: 16px;
    color: #fff;
    background: #afb5c6;
    border-radius: 8px;
    font-weight: normal;
    vertical-align: text-top;
}
.top1{
    display: inline-block;
    width: 44px;
    height: 16px;
    margin-right: 6px;
    margin-top: 2px;
    overflow: hidden;
    text-align: center;
    line-height: 16px;
    color: #fff;
    background: #df3e3e;
    border-radius: 8px;
    font-weight: normal;
    vertical-align: text-top;
}
.top2{
    display: inline-block;
    width: 44px;
    height: 16px;
    margin-right: 6px;
    margin-top: 2px;
    overflow: hidden;
    text-align: center;
    line-height: 16px;
    color: #fff;
    background: #fa7d3c;
    border-radius: 8px;
    font-weight: normal;
    vertical-align: text-top;
}
table { width: 650px; min-height: 25px; line-height: 25px; text-align: center; border-collapse: collapse; padding:2px;margin-left: 38px;}
table,table tr th, table tr td { border:1px solid #c1c1c1; }
.comment_list{
    margin-top:10px; margin-bottom:10px;
}
.comment{
    width: 30px;font-size:16px;float: left;text-align: center;font-size: 14px;margin: 0 auto;font-weight: bold;color: #FF9902;
}
.comment.active{
    color: #49A3D7;
}
.ranking{
    font-size: 16px;color: #FF9902;
}
.ranking.active{
    font-size: 14px;color: #49A3D7;
}
</style>
